import PropTypes from "prop-types";
import clsx from "clsx";
import logo from './RV-01.png'
import { Link } from "react-router-dom";

const Logo = ({ logoClass }) => {
  return (
    <div className={clsx(logoClass)}>
      <Link to={process.env.PUBLIC_URL + "/"}>
      <img alt="" src={logo} width='100' height='50'/>
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;
