/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect,useState } from "react";
import SEO from "../../components/seo";
import { useParams } from 'react-router-dom';
import clsx from "clsx";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Card from 'react-bootstrap/Card';
import axios from "axios";
import LayoutOne from "../../layouts/LayoutOne";
import Accordion from 'react-bootstrap/Accordion';

const RvDetail = () => {
    
    const [loading,setLoading]=useState(false)
    const [detail,setDetail]=useState(null)
    const param=useParams()
    const {id}=param

    useEffect(() => {
        getRvDetail();
      }, []);
    
      const getRvDetail = async () => {   
        setLoading(true) 
        try {
          // eslint-disable-next-line no-unused-vars
          const response = await axios
            .get(`https://rvapi.dev-nuh.xyz/api/user/single/rv/view/${id}`)
            .then((response) => {
              console.log(response?.data?.data);
              setDetail(response?.data?.data)
            setLoading(false)
            })
            .catch((e) => console.log(e));
        } catch (e) {
            setLoading(false)
          console.log(e);
        }
      };

  return (
    <Fragment>
      <SEO
        titleTemplate="Home"
        description="Product home of RV."
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >

      <div
      className={clsx("product-areapb-60 py-5")}
       style={{backgroundColor:'#fafbfc'}}
    >
      <div className="container-md mt-5 ">
        <p style={{color:'#212b35'}} className="fs-2 fw-bold py-2">RV Specification</p>
          {loading && <Skeleton count={1} height='50px' width='600px' />}
        <p style={{color:'#919eab'}} className="fs-5 fw-normal">{`${detail?.generic_type} ${detail?.year} ${detail?.make} ${detail?.display_name}`}</p>
          {loading && <Skeleton count={1} height='40px' width='300px' />}

               <div className="container-md mt-5 ">
                    <div className="col-xl-8 col-md-12 col-lg-0 col-sm-12 my-4">
                    <Card style={{cursor:'pointer', border: 'none',backgroundColor:'transparent'}}>
                      <div className="row g-0">
                        <div className="col-md-6">
                        {loading && <Skeleton count={1} height='300px' width='900px' />}
                          <Card.Img variant="top" src={`https://rvapi.dev-nuh.xyz/uploads/rvs/${detail?.image}`} height='300' />
                        </div>
                        <div className="col-md-6">
                          <Card.Body className='pt-4'>
                           <div className='d-flex justify-content-between py-2 border-bottom-1'>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 400,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>MSRP</Card.Title>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 500,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>${detail?.price_msrp}</Card.Title>
                           </div>
                           <div className='d-flex justify-content-between py-2 border-bottom-1'>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 400,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>Type</Card.Title>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 500,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>${detail?.generic_type}</Card.Title>
                           </div> 
                           <div className='d-flex justify-content-between py-2 border-bottom-1'>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 400,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>Year</Card.Title>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 500,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>${detail?.year}</Card.Title>
                           </div>
                           <div className='d-flex justify-content-between py-2 border-bottom-1'>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 400,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>Make</Card.Title>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 500,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>{detail?.make}</Card.Title>
                           </div>
                           <div className='d-flex justify-content-between py-2 border-bottom-1'>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 400,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>Parent Comapny</Card.Title>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 500,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>${detail?.parent_company}</Card.Title>
                           </div>
                           <div className='d-flex justify-content-between py-2'>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 400,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>Model </Card.Title>
                           <Card.Title className="lh-sm" style={{ fontSize: '16px', fontWeight: 500,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>${detail?.display_name}</Card.Title>
                           </div>
                           
                            </Card.Body>    
                        </div>
                      </div>
                    </Card>

                   <div className="my-5">
                   {loading && <Skeleton count={3} height='50px' width='900px' />}
                   {loading && <Skeleton count={1} height='50px' width='900px' />}
                   {loading && <Skeleton count={1} height='50px' width='900px' />}

                   <Accordion defaultActiveKey="0">
                   <Accordion.Item eventKey="0">
                     <Accordion.Header >Identification</Accordion.Header>
                     <Accordion.Body>
                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                       eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                       minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                       culpa qui officia deserunt mollit anim id est laborum.
                     </Accordion.Body>
                   </Accordion.Item>
                   <Accordion.Item eventKey="1">
                     <Accordion.Header>Exterior</Accordion.Header>
                     <Accordion.Body>
                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                       eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                       minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                       culpa qui officia deserunt mollit anim id est laborum.
                     </Accordion.Body>
                   </Accordion.Item>
                   <Accordion.Item eventKey="2">
                     <Accordion.Header>Interior</Accordion.Header>
                     <Accordion.Body>
                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                       eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                       minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                       culpa qui officia deserunt mollit anim id est laborum.
                     </Accordion.Body>
                   </Accordion.Item>
                   <Accordion.Item eventKey="3">
                     <Accordion.Header>Transmisson</Accordion.Header>
                     <Accordion.Body>
                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                       eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                       minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                       culpa qui officia deserunt mollit anim id est laborum.
                     </Accordion.Body>
                   </Accordion.Item>
                   <Accordion.Item eventKey="4">
                     <Accordion.Header>Wheels & Tries</Accordion.Header>
                     <Accordion.Body>
                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                       eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                       minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                       culpa qui officia deserunt mollit anim id est laborum.
                     </Accordion.Body>
                   </Accordion.Item>
                   <Accordion.Item eventKey="5">
                     <Accordion.Header>Brakes</Accordion.Header>
                     <Accordion.Body>
                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                       eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                       minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                       culpa qui officia deserunt mollit anim id est laborum.
                     </Accordion.Body>
                   </Accordion.Item>
                   <Accordion.Item eventKey="6">
                     <Accordion.Header>Seats</Accordion.Header>
                     <Accordion.Body>
                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                       eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                       minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                       culpa qui officia deserunt mollit anim id est laborum.
                     </Accordion.Body>
                   </Accordion.Item>
              
                 </Accordion>
                   </div>
                 
                  </div>
            
                </div>
             
      </div>
    </div>

    

      </LayoutOne>
    </Fragment>
  );
};

export default RvDetail;
