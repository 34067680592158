const { createSlice } = require('@reduxjs/toolkit');

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        dropdowns: [],
        categories: [],
        categoryProduct: [],
        isLoading: false,
        error: null,
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
          },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setProducts(state, action) {
            state.isLoading = false;
            console.log(action.payload)
            state.products = action.payload;
            state.categoryProduct=state.products
        },
        setDropdowns(state, action) {
            state.isLoading = false;
            console.log(action.payload)
            state.dropdowns = action.payload;
        },
        setCategories(state, action) {
            state.isLoading = false;
            state.categories = action.payload;
        },
        setCategoryId(state,action){
            console.log(action.payload)
            state.isLoading = false;
            if(action.payload === 'all'){
                state.categoryProduct=state.products
            }
            else{
                const temp= state.products.filter(e=>e?.category_id === action.payload)
                state.categoryProduct=temp
            }
        }
    },
});

export const { setProducts,hasError,startLoading,setCategories,setCategoryId ,setDropdowns} = productSlice.actions;
export default productSlice.reducer;

