import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FilterRv from "./pages/home/FilterRv";
import RvDetail from "./pages/home/RvDetails";

const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const Product = lazy(() => import("./pages/shop-product/Product"));


const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeFashion/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/all-rv"}
                element={<FilterRv/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/rv-detail/:id"}
                element={<RvDetail/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                element={<ShopGridStandard/>}
              />
         
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />
            

              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
  );
};

export default App;