/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react";
import SEO from "../../components/seo";
import { useLocation,useNavigate } from 'react-router-dom';
import clsx from "clsx";
import 'react-loading-skeleton/dist/skeleton.css'
import Card from 'react-bootstrap/Card';
import LayoutOne from "../../layouts/LayoutOne";

const FilterRv = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const {data,searchFrom} = location.state;

   console.log(data)

  return (
    <Fragment>
      <SEO
        titleTemplate="Home"
        description="Product home of RV."
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >

      <div
      className={clsx("product-areapb-60 py-2")}
       style={{backgroundColor:'#fafbfc'}}
    >
      <div className="container-md mt-5 ">
        <h1 style={{color:'#212b35',fontWeight:'600'}} className="fs-2 py-3">{searchFrom}</h1>
        <div className='col-xl-8 col-md-12 col-lg-0 col-sm-12'>
        <p style={{color:'#919eab',fontSize:'19px',lineHeight:'1.50em',fontWeight:'400'}} className="my-4">Located in the heart of Amish country (Middlebury, IN) and with a history dating back to 1968, Jayco produces what it calls America's most liveable RVs. One of the biggest RV lineups in the industry, Jayco makes everything from expandable camping trailers to luxury Class A motorhomes.</p>
        </div>


              {data?.map(rv => {
                return (
                    <div className="col-xl-8 col-md-12 col-lg-0 col-sm-12 my-4" key={rv.id} onClick={()=>{navigate(`/rv-detail/${rv?.id}`)}}>
                    <Card style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px', cursor: 'pointer', border: 'none' }}>
                      <div className="row g-0">
                        <div className="col-md-4">
                          <Card.Img variant="top" src={`https://rvapi.dev-nuh.xyz/uploads/rvs/${rv?.image}`} height='200' />
                        </div>
                        <div className="col-md-8">
                          <Card.Body className='p-3'>
                           <div className='d-flex justify-content-between'>
                           <Card.Title className="lh-sm" style={{ fontSize: '18px', fontWeight: 600,color:'#454f5b',fontFamily: 'Poppins,sans-serif' }}>{rv?.title}</Card.Title>
                          <div>
                          <Card.Title className="lh-sm" style={{ fontSize: '18px', fontWeight: 600,color:'#454f5b',fontFamily: 'Poppins,sans-serif',marginBottom:'auto' }}>${rv?.price_msrp}</Card.Title>
                          <Card.Title className="lh-sm" style={{ fontSize: '14px', fontWeight: 400,color:'#454f5b',fontFamily: 'Poppins,sans-serif',marginTop:'2px' }}>MSRP</Card.Title>
                          </div>
                           </div>
                            <Card.Text >
                          {`${rv?.generic_type} - ${rv?.year} - ${rv?.make} - ${rv?.display_name}`}
                          </Card.Text>
                          <button className='btn btn-outline-danger mt-4'>See Full Specs</button>
                            </Card.Body>    
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            
      </div>
    </div>

      </LayoutOne>
    </Fragment>
  );
};

export default FilterRv;
